import React, { FC } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  DashboardPage,
  Auth,
  Analytics,
  Environments,
  ItemPage,
  Museums,
  Animations,
  BackgroundFiles,
  Series,
  Reactions,
  AllWorlds,
  AllEvents,
  Features,
  Category,
} from './pages';
import { ProtectedRoutes } from './utils/PrivateRoute';
import { LobbyWorlds } from './pages/LobbyWorlds';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Auth />} />

        <Route
          path='/users'
          element={
            <ProtectedRoutes>
              <DashboardPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/analytics'
          element={
            <ProtectedRoutes>
              <Analytics />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/items'
          element={
            <ProtectedRoutes>
              <ItemPage />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/environments'
          element={
            <ProtectedRoutes>
              <Environments />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/museums'
          element={
            <ProtectedRoutes>
              <Museums />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/animations'
          element={
            <ProtectedRoutes>
              <Animations />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/Background-files'
          element={
            <ProtectedRoutes>
              <BackgroundFiles />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/series'
          element={
            <ProtectedRoutes>
              <Series />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/reactions'
          element={
            <ProtectedRoutes>
              <Reactions />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/allWorlds'
          element={
            <ProtectedRoutes>
              <AllWorlds />
            </ProtectedRoutes>
          }
        />

        <Route
          path='/LobbyWorlds'
          element={
            <ProtectedRoutes>
              <LobbyWorlds />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/category'
          element={
            <ProtectedRoutes>
              <Category />
            </ProtectedRoutes>
          }
        />

        <Route
          path='/userCustomEvents'
          element={
            <ProtectedRoutes>
              <AllEvents />
            </ProtectedRoutes>
          }
        />
        <Route
          path='/features'
          element={
            <ProtectedRoutes>
              <Features />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
