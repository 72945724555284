import { Controller } from 'react-hook-form'
import { RawTextArea } from './RawTextArea'
import type { FormTextAreaProps } from './interface'

export const FormTextArea: React.FC<FormTextAreaProps> = ({
  name = '',
  rows = 5,
  type = 'text',
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...rest } }) => (
        <RawTextArea type={type} rows={rows} inputRef={ref} {...props} {...rest} />
      )}
    />
  )
}
