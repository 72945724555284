import {  Sidebar } from '../..';
import { sideBarMenu } from '../../../utils';
import { CategoryHeader } from '../../Organisms/CategoryHeader';

export const CategoryTemlate = () => {
  return (
    <div className='flex h-screen'>
      <Sidebar sideBarMenu={sideBarMenu} />
      <CategoryHeader />
    </div>
  );
};
