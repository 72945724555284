import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useReactions } from '../../../hooks';
import { FilteredTableData, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { AddReactionsModal, TableHeader } from '../../Molecules';
import { Spinner } from 'flowbite-react';
import { DeleteModal } from '../../Molecules/Models/deleteModal';
export const ReactionsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [usersData, setUsersData] = useState<any>([]);
  const { reactionsAsync, addReactionsAsync, reactionsDeleteAsync } = useReactions();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await reactionsAsync(data, entries);
    } catch (error) {}
  };

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handlePageChange = async (pageNumber: number) => {
    setLoading(true);
    setIsTrue(false);
    const data = await getUserData(pageNumber, entries);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.count);
      setPageNumber(pageNumber);
      const filteredData = await FilteredTableData(data.data, tableData);
      console.log(filteredData);
      filteredData.forEach((item) => {
        item.createdAt = convertTimeZone(item.createdAt);
        item.delete = (
          <div className='flex justify-center'>
            <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
              Delete
            </Button>
          </div>
        );
      });
      setUsersData(filteredData);
      setIsTrue(true);
    }
  };
  const handlDelete = async (id: number) => {
    try {
      const data = await reactionsDeleteAsync(id);
      if (data) {
        await handlePageChange(pageNumber);
        proceedModalCloseDelete();
      }
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };

  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };
  useMemo(async () => {
    handlePageChange(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, entries, searchTerm]);
  const handleDataReactions = async (data: FormData) => {
    try {
      await addReactionsAsync?.(data);
      handlePageChange(pageNumber);
      close();
    } catch (error) {}
  };
  const { Modal: AddModal, open, close } = useModal(<AddReactionsModal save={handleDataReactions} />, 'Add Reaction');
  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} />, '');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">reactions</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => handlePageChange(pageNumber)}>{!isTrue ? <Spinner /> : <RefetchIcon />}</button>
            <Button onClick={open} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add reactions
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          tableHeaderRow={tableHeaderRow}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedDeleteModal />
      </div>
    </main>
  );
};
