import {CategoryService} from "../../api-services"
import { FieldValues } from "react-hook-form";

export const useCategory = () => {
    const categoryAsync = (pageSize: number,pageNumber:number) => CategoryService.getCategory(pageSize,pageNumber)
    const categoryAddAsync = (data: FieldValues) => CategoryService.addCategory(data)
    const categoryDeleteAsync = (id: number) => CategoryService.deleteCategory(id)
    
    return {
        categoryAsync,
        categoryAddAsync,
        categoryDeleteAsync
      } 
}