import { getAuth } from "./helper";
import { checkAccessToken } from "./storage";
export const OPTIONS = [
  // { value: "view-profile", label: " View Profile" },
  // { value: "contacts", label: "contacts" },
  { value: "analytics", label: "Analytics" },
  // { value: "settings", label: "Settings" },
  { value: "/", label: "Logout" },
];
const token = checkAccessToken()
export const BASE_API_ENDPOINT = process.env.REACT_APP_PUBLIC_NETWORK_TYPE === "testnet" ?  "https://admin-dev.xana.net" : "https://admin-api.xana.net"
// Boolean(token)
//   ? "https://admin-dev.xana.net"
//   : "https://api-test.xana.net";


export const sideBarMenu = [
  {
    title: 'Users',
    route: 'users',
    Icon: 'https://ik.imagekit.io/xanalia/xana/User.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/UserFill.svg',
  },
  {
    title: 'Items ',
    route: 'items ',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Items.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/ItemsFill.svg',
  },
  {
    title: 'Environments',
    route: 'environments',
    Icon: 'https://xanalia.s3.ap-southeast-1.amazonaws.com/xana/Enviroment.svg',
    fillIcon: 'https://xanalia.s3.ap-southeast-1.amazonaws.com/xana/EnviromentFill.svg',
  },

  {
    title: 'Museums',
    route: 'museums',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Museum.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/MuseumFill.svg',
  },
  {
    title: 'Animations',
    route: 'animations',
    Icon: 'https://ik.imagekit.io/xanalia/xana/AnimationFill.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/Animation.svg',
  },
  {
    title: 'BackGround Files',
    route: 'background-files',
    Icon: 'https://ik.imagekit.io/xanalia/xana/BackgroundFiles.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/BackgroundFilesFill.svg',
  },
  {
    title: 'Features',
    route: 'features',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Features.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/FeaturesFill.svg',
  },

  {
    title: 'Series',
    route: 'series',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Series.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/SeriesFill.svg',
  },
  {
    title: 'Category',
    route: 'category',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Worlds.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/WorldsFill.svg',
  },
  {
    title: 'Reactions',
    route: 'reactions',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Reaction.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/ReactionFill.svg',
  },
  {
    title: 'AllWorlds',
    route: 'allWorlds',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Worlds.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/WorldsFill.svg',
  },
  {
    title: 'All Events',
    route: 'userCustomEvents',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Events.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/EventsFill.svg',
  },

  {
    title: 'LobbyWorlds',
    route: 'LobbyWorlds',
    Icon: 'https://ik.imagekit.io/xanalia/xana/Worlds.svg',
    fillIcon: 'https://ik.imagekit.io/xanalia/xana/WorldsFill.svg',
  },
];

export const settingOptions = [
  // { value: "view-profile", label: " View Profile" },
  // { value: "contacts", label: "contacts" },
  { value: "analytics", label: "Analytics" },
  // { value: "settings", label: "Settings" },
  { value: "/", label: "Logout" },
];