import { useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useSeries } from '../../../hooks';
import { FilteredTableData, convertTimeZone } from '../../../utils/helper';
import { Button, useModal } from '../../Atoms';
import { AddSetModal, TableHeader } from '../../Molecules';
import { FieldValues } from 'react-hook-form';
import { ProceedModal } from '../../Molecules/Models/ProceedModal';

export const SeriesHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState<any>([]);
  const { seriesAsync, seriesAddAsync, seriesDeleteAsync } = useSeries();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowData, setRowData] = useState<number | null>(null);
  const [totalDataCount, setTotalDataCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = async (data: number, entries: number) => {
    try {
      return await seriesAsync(data, entries);
    } catch (err) {}
  };
  const onDeleteSeries = async (id: number) => {
    try {
      const res = await seriesDeleteAsync?.(id);
      proceedModalClose();
      if (res) {
        handlePageChange(pageNumber);
      }
    } catch (error) {}
  };
  const handlePageChange = async (page: number) => {
    setLoading(true);
    const data = await getUserData(page, entries);
    if (data) {
      setLoading(false);
      setTotalDataCount(data.count);
      setPageNumber(page);
      const filteredData = await FilteredTableData(data.data, tableData);
      filteredData.forEach((item) => {
        item.createdAt = convertTimeZone(item.createdAt);
        item.delete = (
          <div className='flex justify-center'>
            <Button onClick={() => modalData(item.id)} className='bg-red-600 py-2 px-4 !text-white hover:bg-red-600'>
              Delete Series
            </Button>
          </div>
        );
      });
      setUsersData(filteredData);
    }
  };
  useMemo(async () => {
    handlePageChange(pageNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries]);
  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };
  const handleSet = async (data: FieldValues) => {
    try {
      const res = await seriesAddAsync?.(data);
      if (res) {
        handlePageChange(pageNumber);
        close?.();
      }
    } catch (error) {}
  };
  const { Modal: AddModal, open, close } = useModal(<AddSetModal submit={handleSet} />, 'Add Series');

  const handleProceed = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalClose();
    }
    if (rowData !== null) {
      onDeleteSeries(rowData);
    }
  };

  const modalData = (id: number) => {
    proceedModalOpen();
    setRowData(id);
  };

  const {
    Modal: AddProceedModal,
    open: proceedModalOpen,
    close: proceedModalClose,
  } = useModal(<ProceedModal proceed={handleProceed} />, '');

  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">series</p>
          </div> */}
          <div style={{ marginLeft: 'auto' }}>
            <Button onClick={() => open()} color='primary' size='small' className='w-full !px-10 !bg-blue-600'>
              Add series
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        <Table
          tableDate={usersData}
          searchItem={searchTerm}
          entries={entries}
          keysToFilter={tableData}
          tableHeaderRow={tableHeaderRow}
          totalDataCount={totalDataCount}
          pageNumber={pageNumber}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />
        <AddModal />
        <AddProceedModal />
      </div>
    </main>
  );
};
